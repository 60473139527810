import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Card,
    CardContent,
    Divider
} from '@mui/material';
import { LegoPart, LegoColor, KnownColor } from '../../types/lego.types';

interface PartDetailsProps {
    open: boolean;
    onClose: () => void;
    part: LegoPart | null;
    color?: LegoColor;
    colorId?: number;
}

const StatCard: React.FC<{ title: string; value: string | number }> = ({ title, value }) => (
    <Card variant="outlined">
        <CardContent>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h6">
                {value}
            </Typography>
        </CardContent>
    </Card>
);

const PartDetails: React.FC<PartDetailsProps> = ({ open, onClose, part, color, colorId }) => {
    if (!part) return null;

    const getImageUrl = (part: LegoPart, colorId?: number) => {
        if (!part) return 'https://www.bricklink.com/images/noImage.gif';

        // Try to find the color-specific image in knownColors
        if (colorId && part.knownColors && Array.isArray(part.knownColors)) {
            const colorVariant = part.knownColors.find(
                (color: KnownColor) => color.color_id === colorId
            );

            // For the part details dialog, we want to use the full-size image URL
            if (colorVariant) {
                return `https://img.bricklink.com/ItemImage/PN/${colorId}/${part.no}.png`;
            }
        }

        // If no color variant found or no colorId provided, use the default part image
        if (part.imageUrl) {
            return part.imageUrl.startsWith('//') ? `https:${part.imageUrl}` : part.imageUrl;
        }

        return 'https://www.bricklink.com/images/noImage.gif';
    };

    const ColorBox: React.FC<{ colorCode: string }> = ({ colorCode }) => (
        <Box
            sx={{
                width: 20,
                height: 20,
                bgcolor: `#${colorCode}`,
                border: '1px solid rgba(0, 0, 0, 0.12)',
                display: 'inline-block',
                verticalAlign: 'middle',
                mr: 1
            }}
        />
    );

    // Calculate statistics
    const stockQuantity = part.priceGuide?.price_data.total_quantity || 0;
    const soldQuantity = part.soldPriceGuide?.price_data.total_quantity || 0;
    const numSales = part.soldPriceGuide?.price_data.unit_quantity || 0;
    const stockToSoldRatio = soldQuantity > 0 ? (stockQuantity / soldQuantity).toFixed(2) : 'N/A';

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h5" component="div">
                    {part.name} ({part.no})
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    {/* Part Image and Basic Details */}
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    component="img"
                                    src={getImageUrl(part, colorId)}
                                    alt={part.name}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        mb: 2
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Details</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Part Number:</strong> {part.no}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Name:</strong> {part.name}
                                    </Typography>
                                    {color && (
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Color:</strong>
                                            <Box display="flex" alignItems="center" mt={1}>
                                                <ColorBox colorCode={color.color_code} />
                                                <span>
                                                    {color.color_name} ({color.color_id})
                                                </span>
                                            </Box>
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Statistics Grid */}
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>Market Statistics</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard 
                                    title="Current Stock" 
                                    value={stockQuantity.toLocaleString()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard 
                                    title="Quantity Sold" 
                                    value={soldQuantity.toLocaleString()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard 
                                    title="Number of Sales" 
                                    value={numSales.toLocaleString()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard 
                                    title="Stock/Sold Ratio" 
                                    value={stockToSoldRatio}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Price Guide Section */}
                    {(part.priceGuide || part.soldPriceGuide) && (
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h6" gutterBottom>Price Guide</Typography>
                            <Grid container spacing={3}>
                                {part.priceGuide && (
                                    <Grid item xs={12} md={6}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="h6" gutterBottom>Current Stock</Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Min Price: ${parseFloat(part.priceGuide.price_data.min_price).toFixed(2)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Avg Price: ${parseFloat(part.priceGuide.price_data.avg_price).toFixed(2)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Qty Avg Price: ${parseFloat(part.priceGuide.price_data.qty_avg_price).toFixed(2)}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                                {part.soldPriceGuide && (
                                    <Grid item xs={12} md={6}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="h6" gutterBottom>Recently Sold</Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Min Price: ${parseFloat(part.soldPriceGuide.price_data.min_price).toFixed(2)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Avg Price: ${parseFloat(part.soldPriceGuide.price_data.avg_price).toFixed(2)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Qty Avg Price: ${parseFloat(part.soldPriceGuide.price_data.qty_avg_price).toFixed(2)}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PartDetails;
