import React, { useState, useEffect } from 'react';
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Button,
    Alert,
    CircularProgress,
    SelectChangeEvent,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Stack
} from '@mui/material';
import { apiService, User, UserRole } from '../../services/api.service';

interface PasswordDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (password: string) => void;
    username: string;
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({ open, onClose, onSubmit, username }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        if (password.length < 6) {
            setError('Password must be at least 6 characters long');
            return;
        }
        onSubmit(password);
        setPassword('');
        setConfirmPassword('');
        setError('');
    };

    const handleClose = () => {
        setPassword('');
        setConfirmPassword('');
        setError('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Reset Password for {username}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ mt: 1 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        label="New Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Reset Password</Button>
            </DialogActions>
        </Dialog>
    );
};

const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const currentUser = apiService.getCurrentUser();

    const fetchUsers = async () => {
        try {
            setLoading(true);
            setError(null);
            console.log('Fetching users...');
            const response = await apiService.getAllUsers();
            console.log('Users response:', response);
            setUsers(response.data.data);
        } catch (err: any) {
            console.error('Error fetching users:', err);
            setError(err.response?.data?.message || 'Failed to fetch users');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleRoleChange = async (userId: string, newRole: UserRole) => {
        try {
            setError(null);
            setSuccess(null);
            await apiService.updateUserRole(userId, newRole);
            setSuccess('User role updated successfully');
            fetchUsers(); // Refresh user list
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to update user role');
        }
    };

    const handleDeleteUser = async (userId: string) => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }

        try {
            setError(null);
            setSuccess(null);
            await apiService.deleteUser(userId);
            setSuccess('User deleted successfully');
            fetchUsers(); // Refresh user list
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to delete user');
        }
    };

    const handleResetPassword = async (password: string) => {
        if (!selectedUser) return;

        try {
            setError(null);
            setSuccess(null);
            await apiService.resetUserPassword(selectedUser.id, password);
            setSuccess('User password reset successfully');
            setPasswordDialogOpen(false);
            setSelectedUser(null);
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to reset password');
        }
    };

    const openPasswordDialog = (user: User) => {
        setSelectedUser(user);
        setPasswordDialogOpen(true);
    };

    const isCurrentUser = (userId: string) => {
        return currentUser?.id === userId;
    };

    if (loading) {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                User Management
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            {success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {success}
                </Alert>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    {isCurrentUser(user.id) ? (
                                        <Tooltip title="Cannot modify your own role">
                                            <span>
                                                <FormControl size="small" disabled>
                                                    <Select value={user.role}>
                                                        <MenuItem value={user.role}>{user.role}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <FormControl size="small">
                                            <Select
                                                value={user.role}
                                                onChange={(e: SelectChangeEvent) =>
                                                    handleRoleChange(user.id, e.target.value as UserRole)
                                                }
                                            >
                                                <MenuItem value="admin">Admin</MenuItem>
                                                <MenuItem value="user">User</MenuItem>
                                                <MenuItem value="unapproved">Unapproved</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        {user.role !== 'admin' && (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => openPasswordDialog(user)}
                                            >
                                                Reset Password
                                            </Button>
                                        )}
                                        {isCurrentUser(user.id) ? (
                                            <Tooltip title="Cannot delete your own account">
                                                <span>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        size="small"
                                                        disabled
                                                    >
                                                        Delete
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() => handleDeleteUser(user.id)}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <PasswordDialog
                open={passwordDialogOpen}
                onClose={() => {
                    setPasswordDialogOpen(false);
                    setSelectedUser(null);
                }}
                onSubmit={handleResetPassword}
                username={selectedUser?.username || ''}
            />
        </Container>
    );
};

export default UserManagement;
