import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    CircularProgress,
    Card,
    CardContent,
    Divider
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { 
    LegoSet, 
    LegoColor, 
    PriceGuideData, 
    LegoPartsMap,
    KnownColor,
    LegoPart
} from '../../types/lego.types';
import { apiService } from '../../services/api.service';
import PartDetails from './PartDetails';
import PartOutSummary from './PartOutSummary';

interface LegoSetDetailsProps {
    open: boolean;
    onClose: () => void;
    set: LegoSet | null;
}

const PriceGuideBox: React.FC<{ title: string; priceData: any }> = ({ title, priceData }) => (
    <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Min Price</Typography>
                    <Typography variant="body1">${parseFloat(priceData.min_price).toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Max Price</Typography>
                    <Typography variant="body1">${parseFloat(priceData.max_price).toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Average Price</Typography>
                    <Typography variant="body1">${parseFloat(priceData.avg_price).toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Quantity Average Price</Typography>
                    <Typography variant="body1">${parseFloat(priceData.qty_avg_price).toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Unit Quantity</Typography>
                    <Typography variant="body1">{priceData.unit_quantity}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Total Quantity</Typography>
                    <Typography variant="body1">{priceData.total_quantity}</Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

const LegoSetDetails: React.FC<LegoSetDetailsProps> = ({ open, onClose, set }) => {
    const [colors, setColors] = useState<Map<number, LegoColor>>(new Map());
    const [parts, setParts] = useState<LegoPartsMap>({});
    const [priceGuide, setPriceGuide] = useState<PriceGuideData | null>(null);
    const [loading, setLoading] = useState(false);
    const [selectedPart, setSelectedPart] = useState<LegoPart | null>(null);
    const [selectedPartColor, setSelectedPartColor] = useState<LegoColor | null>(null);
    const [selectedPartColorId, setSelectedPartColorId] = useState<number | null>(null);
    const [partDetailsOpen, setPartDetailsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!set?.subsets?.length) return;

            try {
                setLoading(true);
                const colorIds = Array.from(new Set(set.subsets.map(subset => subset.color_id)));
                const partNumbers = Array.from(new Set(set.subsets.map(subset => subset.no)));

                const [colorsResponse, priceGuideResponse, partsResponse] = await Promise.all([
                    apiService.getColorsByIds(colorIds),
                    apiService.getPriceGuide(set.type, set.no),
                    apiService.getPartsByNumbers(partNumbers)
                ]);

                const colorMap = new Map<number, LegoColor>(
                    colorsResponse.data.data.map((color: LegoColor) => [color.color_id, color])
                );
                setColors(colorMap);
                setPriceGuide(priceGuideResponse.data.data);
                setParts(partsResponse.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open && set) {
            fetchData();
        }
    }, [open, set]);

    const handlePartClick = (part: LegoPart, colorId: number, color: LegoColor | undefined) => {
        setSelectedPart(part);
        setSelectedPartColorId(colorId);
        setSelectedPartColor(color || null);
        setPartDetailsOpen(true);
    };

    const getStockToSoldRatio = (part: LegoPart | undefined): string => {
        if (!part) return '-';
        const stockQuantity = part.priceGuide?.price_data.total_quantity || 0;
        const soldQuantity = part.soldPriceGuide?.price_data.total_quantity || 0;
        return soldQuantity > 0 ? (stockQuantity / soldQuantity).toFixed(2) : '-';
    };

    const handleExport = () => {
        if (!set || !parts) return;

        // Create CSV header
        const headers = [
            'Part Number',
            'Name',
            'Type',
            'Color ID',
            'Color Name',
            'Quantity',
            'Extra',
            'Current Stock Min Price',
            'Current Stock Qty',
            'Recently Sold Avg Price',
            'Recently Sold Qty',
            'Stock/Sold Ratio'
        ].join(',');

        // Create CSV rows
        const rows = set.subsets.map(subset => {
            const part = parts[subset.no];
            const color = colors.get(subset.color_id);
            
            return [
                `"${subset.no}"`,
                `"${subset.name}"`,
                `"${subset.type}"`,
                subset.color_id,
                `"${color?.color_name || ''}"`,
                subset.quantity,
                subset.extra_quantity,
                part?.priceGuide?.price_data.min_price || '',
                part?.priceGuide?.price_data.total_quantity || '',
                part?.soldPriceGuide?.price_data.qty_avg_price || '',
                part?.soldPriceGuide?.price_data.total_quantity || '',
                getStockToSoldRatio(part)
            ].join(',');
        });

        // Combine headers and rows
        const csvContent = [headers, ...rows].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${set.no}-${set.name}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (!set) return null;

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {set.name} ({set.no})
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        {/* Set Image and Details */}
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Box
                                        component="img"
                                        src={set.imageUrl.startsWith('//') ? `https:${set.imageUrl}` : set.imageUrl}
                                        alt={set.name}
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            objectFit: 'contain',
                                            mb: 2
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom>Details</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Set Number:</strong> {set.no}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Year Released:</strong> {set.yearReleased}
                                        </Typography>
                                        {set.weight && (
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Weight:</strong> {set.weight}g
                                            </Typography>
                                        )}
                                        {(set.dimX || set.dimY || set.dimZ) && (
                                            <Typography variant="body1" gutterBottom>
                                                <strong>Dimensions:</strong> {set.dimX}cm x {set.dimY}cm x {set.dimZ}cm
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Price Guide Section */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography variant="h6">Price Guide</Typography>
                                <Button
                                    variant="outlined"
                                    startIcon={<FileDownloadIcon />}
                                    onClick={handleExport}
                                >
                                    Export
                                </Button>
                            </Box>
                            {loading ? (
                                <Box display="flex" justifyContent="center" p={2}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        {priceGuide?.stock && (
                                            <PriceGuideBox 
                                                title="Current Stock" 
                                                priceData={priceGuide.stock.price_data} 
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {priceGuide?.sold && (
                                            <PriceGuideBox 
                                                title="Recently Sold" 
                                                priceData={priceGuide.sold.price_data} 
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        {/* Part Out Summary */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                            {loading ? (
                                <Box display="flex" justifyContent="center" p={2}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <PartOutSummary 
                                    parts={parts}
                                    subsets={set.subsets}
                                />
                            )}
                        </Grid>

                        {/* Parts List */}
                        {set.subsets && set.subsets.length > 0 && (
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" gutterBottom>Parts</Typography>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" p={2}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Image</TableCell>
                                                    <TableCell>Part Number</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Color</TableCell>
                                                    <TableCell align="right">Stock Price</TableCell>
                                                    <TableCell align="right">Sold Price</TableCell>
                                                    <TableCell align="right">Stock/Sold</TableCell>
                                                    <TableCell align="right">Quantity</TableCell>
                                                    <TableCell align="right">Extra</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {set.subsets.map((subset) => {
                                                    const color = colors.get(subset.color_id);
                                                    const part = parts[subset.no];
                                                    return (
                                                        <TableRow 
                                                            key={`${subset.no}-${subset.color_id}`}
                                                            hover
                                                            onClick={() => part && handlePartClick(part, subset.color_id, color)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>
                                                                {part && (
                                                                    <Box
                                                                        component="img"
                                                                        src={`https://img.bricklink.com/ItemImage/PN/${subset.color_id}/${part.no}.png`}
                                                                        alt={part.name}
                                                                        sx={{
                                                                            width: 50,
                                                                            height: 50,
                                                                            objectFit: 'contain'
                                                                        }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{subset.no}</TableCell>
                                                            <TableCell>{subset.name}</TableCell>
                                                            <TableCell>
                                                                <Chip 
                                                                    label={subset.type} 
                                                                    size="small"
                                                                    color={subset.type === 'PART' ? 'primary' : 'secondary'}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {color ? (
                                                                    <Box display="flex" alignItems="center">
                                                                        <Box
                                                                            sx={{
                                                                                width: 20,
                                                                                height: 20,
                                                                                bgcolor: `#${color.color_code}`,
                                                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                                                display: 'inline-block',
                                                                                verticalAlign: 'middle',
                                                                                mr: 1
                                                                            }}
                                                                        />
                                                                        <span>
                                                                            {color.color_name} ({color.color_id})
                                                                        </span>
                                                                    </Box>
                                                                ) : (
                                                                    `Color ID: ${subset.color_id}`
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {part?.priceGuide ? (
                                                                    `$${parseFloat(part.priceGuide.price_data.min_price).toFixed(2)}`
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {part?.soldPriceGuide ? (
                                                                    `$${parseFloat(part.soldPriceGuide.price_data.qty_avg_price).toFixed(2)}`
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {getStockToSoldRatio(part)}
                                                            </TableCell>
                                                            <TableCell align="right">{subset.quantity}</TableCell>
                                                            <TableCell align="right">{subset.extra_quantity}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <PartDetails
                open={partDetailsOpen}
                onClose={() => setPartDetailsOpen(false)}
                part={selectedPart}
                color={selectedPartColor || undefined}
                colorId={selectedPartColorId || undefined}
            />
        </>
    );
};

export default LegoSetDetails;
