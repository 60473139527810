import axios, { AxiosInstance } from 'axios';
import { LegoSetsResponse, LegoSetResponse, LegoColorsResponse, LegoColorResponse, PriceGuideResponse, LegoPartsResponse } from '../types/lego.types';

type UserRole = 'admin' | 'user' | 'unapproved';

interface User {
    id: string;
    username: string;
    email: string;
    role: UserRole;
}

interface AuthResponse {
    success: boolean;
    token: string;
    user: User;
}

interface UsersResponse {
    success: boolean;
    data: User[];
}

interface MessageResponse {
    success: boolean;
    message: string;
}

class ApiService {
    private api: AxiosInstance;
    private static instance: ApiService;

    constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.setupInterceptors();
    }

    private setupInterceptors() {
        // Request interceptor
        this.api.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('token');
                if (token && config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Response interceptor
        this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    // Clear auth data and redirect to login
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.href = '/login';
                }
                return Promise.reject(error);
            }
        );
    }

    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    }

    // Generic request methods
    async get<T>(endpoint: string, params?: any) {
        return this.api.get<T>(endpoint, { params });
    }

    async post<T>(endpoint: string, data: any) {
        return this.api.post<T>(endpoint, data);
    }

    async patch<T>(endpoint: string, data: any) {
        return this.api.patch<T>(endpoint, data);
    }

    async delete<T>(endpoint: string) {
        return this.api.delete<T>(endpoint);
    }

    // Auth methods
    async login(credentials: { email: string; password: string }) {
        const response = await this.post<AuthResponse>('/auth/login', credentials);
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        return response.data;
    }

    async register(userData: { username: string; email: string; password: string }) {
        return this.post<MessageResponse>('/auth/register', userData);
    }

    async logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
    }

    // User management methods
    async getAllUsers() {
        return this.get<UsersResponse>('/admin/users');
    }

    async updateUserRole(userId: string, role: UserRole) {
        return this.patch<MessageResponse>(`/admin/users/${userId}/role`, { role });
    }

    async resetUserPassword(userId: string, newPassword: string) {
        return this.patch<MessageResponse>(`/admin/users/${userId}/password`, { newPassword });
    }

    async deleteUser(userId: string) {
        return this.delete<MessageResponse>(`/admin/users/${userId}`);
    }

    // User profile methods
    async updatePassword(currentPassword: string, newPassword: string) {
        return this.patch<MessageResponse>('/users/password', { currentPassword, newPassword });
    }

    // LEGO Sets methods
    async getLegoSets(params?: {
        page?: number;
        limit?: number;
        year?: number;
        search?: string;
    }) {
        return this.get<LegoSetsResponse>('/items/sets', params);
    }

    async getLegoSetByNumber(number: string) {
        return this.get<LegoSetResponse>(`/items/sets/${number}`);
    }

    // LEGO Parts methods
    async getPartsByNumbers(numbers: string[]) {
        return this.get<LegoPartsResponse>('/items/parts', { numbers: numbers.join(',') });
    }

    // LEGO Colors methods
    async getColorsByIds(colorIds: number[]) {
        return this.get<LegoColorsResponse>('/colors', { colorIds: colorIds.join(',') });
    }

    async getColorById(colorId: number) {
        return this.get<LegoColorResponse>(`/colors/${colorId}`);
    }

    // Price Guide methods
    async getPriceGuide(type: string, no: string) {
        return this.get<PriceGuideResponse>(`/price-guide/${type}/${no}`);
    }

    // Helper methods
    getAuthToken() {
        return localStorage.getItem('token');
    }

    getCurrentUser(): User | null {
        const userStr = localStorage.getItem('user');
        return userStr ? JSON.parse(userStr) : null;
    }

    isAuthenticated() {
        return !!this.getAuthToken();
    }
}

export type { User, UserRole };
export const apiService = ApiService.getInstance();
