import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { LegoPartsMap } from '../../types/lego.types';

interface PartOutSummaryProps {
    parts: LegoPartsMap;
    subsets: Array<{
        no: string;
        quantity: number;
    }>;
}

interface SummaryRow {
    ratio: string;
    currentStockMin: number;
    recentlySoldMin: number;
    partCount: number;
}

interface PartStat {
    ratio: number | null;
    currentStockMin: number;
    recentlySoldMin: number;
    hasData: boolean;
}

const PartOutSummary: React.FC<PartOutSummaryProps> = ({ parts, subsets }) => {
    const calculateSummaryRows = (): SummaryRow[] => {
        const ratioThresholds = [1, 3, 5, 10];
        const rows: SummaryRow[] = [];

        // Calculate ratio and values for each part
        const partStats = subsets.map(subset => {
            const part = parts[subset.no];
            if (!part) return null;

            const stockQuantity = part.priceGuide?.price_data.total_quantity || 0;
            const soldQuantity = part.soldPriceGuide?.price_data.total_quantity || 0;
            const ratio = soldQuantity > 0 ? stockQuantity / soldQuantity : null;
            
            const currentStockMin = part.priceGuide?.price_data.min_price 
                ? parseFloat(part.priceGuide.price_data.min_price) * subset.quantity
                : 0;
            
            const recentlySoldMin = part.soldPriceGuide?.price_data.qty_avg_price
                ? parseFloat(part.soldPriceGuide.price_data.qty_avg_price) * subset.quantity
                : 0;

            return {
                ratio,
                currentStockMin,
                recentlySoldMin,
                hasData: Boolean(part.priceGuide && part.soldPriceGuide)
            } as PartStat;
        }).filter((stat): stat is PartStat => stat !== null);

        // Calculate rows for each ratio threshold
        ratioThresholds.forEach(threshold => {
            const qualifyingParts = partStats.filter(
                stat => stat.ratio !== null && stat.ratio <= threshold
            );

            rows.push({
                ratio: `${threshold}:1`,
                currentStockMin: qualifyingParts.reduce((sum, stat) => sum + stat.currentStockMin, 0),
                recentlySoldMin: qualifyingParts.reduce((sum, stat) => sum + stat.recentlySoldMin, 0),
                partCount: qualifyingParts.length
            });
        });

        // Add "All" row (including parts with no ratio)
        const allParts = partStats.filter(stat => stat.hasData);
        rows.push({
            ratio: 'All',
            currentStockMin: allParts.reduce((sum, stat) => sum + stat.currentStockMin, 0),
            recentlySoldMin: allParts.reduce((sum, stat) => sum + stat.recentlySoldMin, 0),
            partCount: allParts.length
        });

        return rows;
    };

    const summaryRows = calculateSummaryRows();

    return (
        <>
            <Typography variant="h6" gutterBottom>Part Out Summary</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ratio</TableCell>
                            <TableCell align="right">Current Stock Min</TableCell>
                            <TableCell align="right">Recently Sold Avg</TableCell>
                            <TableCell align="right">Part Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summaryRows.map((row) => (
                            <TableRow key={row.ratio}>
                                <TableCell>{row.ratio}</TableCell>
                                <TableCell align="right">
                                    ${row.currentStockMin.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    ${row.recentlySoldMin.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    {row.partCount}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PartOutSummary;
