import React, { useState } from 'react';
import {
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    Box,
    Alert,
    Grid
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { apiService } from '../../services/api.service';

const Profile: React.FC = () => {
    const { user } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleChange = (field: keyof typeof passwords) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPasswords(prev => ({
            ...prev,
            [field]: e.target.value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        // Validate passwords
        if (passwords.newPassword !== passwords.confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        if (passwords.newPassword.length < 6) {
            setError('New password must be at least 6 characters long');
            return;
        }

        try {
            await apiService.patch('/users/password', {
                currentPassword: passwords.currentPassword,
                newPassword: passwords.newPassword
            });

            setSuccess('Password updated successfully');
            setPasswords({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to update password');
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Profile
                </Typography>

                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" color="text.secondary">
                            Username
                        </Typography>
                        <Typography variant="body1">{user?.username}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" color="text.secondary">
                            Email
                        </Typography>
                        <Typography variant="body1">{user?.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" color="text.secondary">
                            Role
                        </Typography>
                        <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                            {user?.role}
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Change Password
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {success}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Current Password"
                        value={passwords.currentPassword}
                        onChange={handleChange('currentPassword')}
                        margin="normal"
                        required
                    />

                    <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        value={passwords.newPassword}
                        onChange={handleChange('newPassword')}
                        margin="normal"
                        required
                    />

                    <TextField
                        fullWidth
                        type="password"
                        label="Confirm New Password"
                        value={passwords.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        margin="normal"
                        required
                    />

                    <Box sx={{ mt: 3 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Update Password
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
};

export default Profile;
