import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './context/AuthContext';
import { Navigation } from './components/common/Navigation';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';
import UserManagement from './components/admin/UserManagement';
import Profile from './components/user/Profile';
import LegoSets from './components/lego/LegoSets';

// Create placeholder components for routes we'll implement later
const Dashboard = () => <div>Dashboard Coming Soon</div>;
const LegoParts = () => <div>Lego Parts Coming Soon</div>;

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            
            {/* Protected Routes */}
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <Navigation>
                    <Routes>
                      <Route path="/" element={<Navigate to="/dashboard" replace />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/lego-sets" element={<LegoSets />} />
                      <Route path="/lego-parts" element={<LegoParts />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route 
                        path="/admin/users" 
                        element={
                          <ProtectedRoute requireAdmin>
                            <UserManagement />
                          </ProtectedRoute>
                        } 
                      />
                      {/* Catch all route */}
                      <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </Routes>
                  </Navigation>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
